import React, {
  memo, useState, useEffect,
} from 'react';
import {
  Grid, makeStyles,
  Divider, List, CircularProgress, RadioGroup, FormControlLabel, Radio,
  Button, TextareaAutosize, TextField,
} from '@material-ui/core';
import { FormLayout, FormQuestion, FormHeader } from '../Common';
import { UI_CONFIG, QUESTION_TYPE } from '../../constants/Constants';
import StarRating from '../Common/StarRating/index';
import { userConstants } from '../../locale/userTexts';
import './index.scss';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: '42px',
    paddingTop: '0px',
  },
  formlabel: {
    '& span': {
      wordBreak: 'break-word',
      fontFamily: 'Frank Ruhl Libre',
    },
  },
}));

function RenderQuestionType(props) {
  const {
    type, options, onChange, response, language, commentType, commentTypeData, onOtherChange,
    handleRadioClick,
  } = props;
  const isMobile = window.innerWidth < 600;
  let ratingConfig;

  const classes = useStyles();
  const renderCount = (Limit) => {
    const fields = [];
    for (let index = 1; index <= Limit; index += 1) {
      fields.push(<div className="star-rating">{index}</div>);
    }
    return fields;
  };
  switch (type) {
    case QUESTION_TYPE.SINGLE_SELECT:
      return (
        <List className={classes.root}>
          <RadioGroup aria-label="quiz" name="quiz" value={response} className="form_response_radio">
            {Array.isArray(options) && options.map((option, index) => {
              const { option: optionText } = option;
              return (
                <>
                  <FormControlLabel
                    className={classes.formlabel}
                    key={`option-${index}-${optionText}`}
                    value={index}
                    control={<Radio className="question_radio_button" onClick={handleRadioClick} />}
                    label={optionText}
                  />
                  {(commentType === 'ANSWER' && option?.isOther && response === options?.length - 1)
                    && (
                      <TextField
                        style={{
                          width: '100%',
                          paddingBottom: '8px',
                        }}
                        id="outlined-textarea"
                        label={option?.placeholderText}
                        placeholder={option?.option}
                        multiline
                        variant="outlined"
                        name="answer"
                        onChange={(e) => onOtherChange(e)}
                      />
                    )}
                </>
              );
            })}
          </RadioGroup>
          {commentType === 'COMMENT' && (
            <>
              <TextField
                style={{
                  width: '100%',
                  paddingBottom: '8px',
                  marginTop: '8px',
                }}
                id="outlined-textarea"
                placeholder={commentTypeData?.placeholderText}
                multiline
                variant="outlined"
                label={commentTypeData?.label}
                name="commentText"
                required={commentTypeData?.isRequired}
                onChange={(e) => onOtherChange(e)}
              />
            </>
          )}
        </List>
      );
    case QUESTION_TYPE.OPEN_ENDED:
      return (
        <TextareaAutosize
          className="text-area-style"
          rowsMin={4}
          placeholder={userConstants(language).textAreaPlaceholder}
          value={response}
          onChange={onChange}
        />
      );
    case QUESTION_TYPE.RATING:
      ratingConfig = {
        size: isMobile ? 'small' : 'medium',
        classNames: 'stars',
        edit: true,
        count: options?.length,
        onChange,
      };
      return (
        <div className="ratingStars">
          <StarRating ratingConfig={ratingConfig} />
          <div className="star-count-container">
            {renderCount(options?.length)}
          </div>
        </div>
      );
    default:
      return null;
  }
}

const QuestionSet = (props) => {
  const {
    questionObj, index, onChange, language,
  } = props;
  const {
    questionText, options, response, description, type, imageUrl, commentType, comment, isRequired, examples,
    simplifiedQuestion,
  } = questionObj;

  const onChangeOption = (event) => {
    const { value } = event.target;
    const newQuestionObj = {
      ...questionObj,
      response: value,
    };
    onChange(index, { ...newQuestionObj });
  };

  const onOtherChange = (event) => {
    const { value, name } = event.target;
    const newQuestionObj = {
      ...questionObj,
      [name]: value,
    };
    questionObj.response = { ...questionObj.response, responseText: value };
    onChange(index, { ...newQuestionObj });
  };

  function handleClick(event) {
    const { value } = event.target;
    let newQuestionObj;
    if (Number(value) === response) {
      newQuestionObj = {
        ...questionObj,
        response: Number(-1),
      };
    } else {
      newQuestionObj = {
        ...questionObj,
        response: Number(value),
      };
    }
    onChange(index, { ...newQuestionObj });
  }

  return (
    <>
      <Grid container spacing={2}>
        <FormQuestion
          index={index}
          questionText={simplifiedQuestion}
          imageUrl={imageUrl}
          isRequired={isRequired}
          examples={examples}
        />
        <Grid item xs={12} md={12}>
          <RenderQuestionType
            type={type}
            commentType={commentType}
            options={options}
            response={response}
            onChange={onChangeOption}
            language={language}
            commentTypeData={comment}
            onOtherChange={onOtherChange}
            handleRadioClick={handleClick}
          />
        </Grid>
      </Grid>
      <Divider
        variant="middle"
        style={{
          margin: '10px 0',
        }}
      />
    </>
  );
};

const RespondentForm = (props) => {
  const {
    questionnaire, questions,
    onSubmit, isLoading, showHeader, language, response, questionIdMap,
  } = props;
  const [questionnaireData, setQuestionnaire] = useState({
    title: '',
    descriptions: '',
  });
  const [questionsData, setAllQuestions] = useState(null);
  const [isValidResponse, setIsValidResponse] = useState(true);

  useEffect(() => {
    if (questionsData) {
      let isValid = true;
      for (let i = 0; i < questionsData.length; i += 1) {
        const d = questionsData[i];
        if (d.isRequired && (d.response <= -1 || d.response === null || d.response === '')) {
          isValid = false;
          break;
        }
        if (d.isRequired && d?.commentType === 'ANSWER' && d?.response === d?.options.length - 1 && (!d?.answer || d?.answer.trim().length === 0)) {
          isValid = false;
          break;
        }
        if (d.isRequired && d?.commentType === 'COMMENT' && d?.comment?.isRequired && (!d?.commentText || d?.commentText?.trim().length === 0)) {
          isValid = false;
          break;
        }
      }
      setIsValidResponse(isValid);
    }
  }, [setIsValidResponse, questionsData]);

  useEffect(() => {
    const getResponse = (question) => {
      const responseData = response?.responses?.find((item) => item?.questionId === question?.id);
      const findIndex = question?.options?.findIndex((item) => item?.option === responseData?.response?.singleSelect?.option);
      return findIndex;
    };
    if (questionnaire) {
      setQuestionnaire({ ...questionnaire });
    }
    if (questions && Array.isArray(questions)) {
      setAllQuestions([...questions.map((d) => ({
        ...d, response: d.type === QUESTION_TYPE.SINGLE_SELECT ? getResponse(d) : null,
      }))]);
    }
  }, [questionnaire, questions, response]);

  const getValue = (d) => {
    let value;
    switch (d.type) {
      case QUESTION_TYPE.SINGLE_SELECT:
        value = d.options[d.response];
        break;
      case QUESTION_TYPE.OPEN_ENDED:
        value = d.response;
        break;
      case QUESTION_TYPE.RATING:
        value = d.options[d.response - 1];
        break;
      default:
        break;
    }
    return value;
  };

  const handleSubmit = async () => {
    const responses = [];
    questionsData.forEach((d) => {
      if (d.response !== -1) {
        if (d?.commentType === 'ANSWER' && d?.answer?.length > 0) {
          responses.push({
            questionId: d.id,
            response: {
              [d.type]: {
                ...getValue(d),
                isOther: true,
                responseText: d?.answer,
              },
            },
            tags: d.tags,
          });
        } else if (d?.commentType === 'COMMENT' && d?.commentText?.length > 0) {
          responses.push({
            questionId: d.id,
            response: {
              [d.type]: getValue(d),
              comment: {
                responseText: d?.commentText,
              },
            },
            tags: d.tags,
          });
        } else {
          responses.push({
            questionId: d.id,
            response: {
              [d.type]: getValue(d),
            },
            tags: d.tags,
          });
        }
      }
    });
    await onSubmit(responses);
  };

  const onChange = (index, updatedData) => {
    const newQuestions = [...questionsData];
    newQuestions[index] = updatedData;
    setAllQuestions(newQuestions);
  };

  // TODO: Add onChange radio button and submit

  return (
    isLoading ? (
      <div className="loaderCont">
        <CircularProgress size={UI_CONFIG.loaderSize} />
      </div>
    ) : (
      <div className="newQuestCont">
        {showHeader && (
          <div className="formSect">
            <FormHeader data={questionnaireData} />
          </div>
        )}
        <div className="formSect">
          <FormLayout>
            <form>
              {
                Array.isArray(questionsData) && questionsData.map((d, index) => (
                  <QuestionSet
                    selected
                    key={`question-set-${index}`}
                    questionObj={d}
                    index={index}
                    onChange={onChange}
                    language={language}
                  />
                ))
              }
              <Button
                type="button"
                variant="outlined"
                className="themeBtn"
                disabled={!isValidResponse}
                onClick={handleSubmit}
              >
                {userConstants(language).submitButtonInUpperCase}
              </Button>
            </form>
          </FormLayout>
        </div>
      </div>
    )
  );
};

export default memo(RespondentForm);
